import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Layout } from "../layouts/Site"
import { Carousel, HeaderPage, PageWidth, Section } from "../components/Containers"
import { AssertPage } from "../components/AssetContext"
import { join, sectionEndLine } from "../styles/styles"
import { Content } from "../components/Content"
import { Link, PageHeading, pageHeadingStyles, Paragraph, SectionHeading } from "../components/Headings"
import { Image } from "../components/Image"
import { RichText } from "../components/RichText"
import { INLINES } from "@contentful/rich-text-types"
import { Helmet } from "react-helmet";

const dateFormat = new Intl.DateTimeFormat('en-CA', {dateStyle: 'medium'})
const longDateFormat = new Intl.DateTimeFormat('en-CA', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})
const timeFormat = new Intl.DateTimeFormat('en-CA', {timeStyle: 'short'})
const longTimeFormat = new Intl.DateTimeFormat('en-CA', {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'})

const CONTINUOUS_DELIVERY_PODCAST = "continuous delivery podcast";
const DEFINITELY_MAYBE_AGILE_PODCAST = "definitely maybe agile podcast";
const GOOD_INTENTIONS_PODCAST = "good intentions podcast";

export default function EventsPage({ location }) {
  const { page, defaultMetadata, sortedEvents } = useStaticQuery(query)

  const allEvents = sortedEvents.nodes.map(withDate())

  const [events, sortEvents] = useState(undefined)

  useEffect(() => {
    if (events)
      return
    const today = new Date()
    sortEvents({
      future: allEvents.filter(e => e.date.getTime() > today.getTime()).reverse(),
      past: allEvents.filter(e => e.date.getTime() <= today.getTime())
    })
  }, [events, allEvents])

  const componentsForCarousel = (events && events.future.map(event => <PageWidth className='bg-primary h-full'><FutureEventCarouselEntry event={event} /></PageWidth>)) || []
  page.contents && componentsForCarousel.push(...page.contents.map(content => <Section key={content.key} cols='auto' className='h-full items-center p-5'>
      {content.image && <Image image={content.image} />}
      <div>
        <Content content={content} richTextStyles={pageHeadingStyles} />
        { content.key === CONTINUOUS_DELIVERY_PODCAST ? <div className="overflow-hidden" style={{height: "200px"}}><iframe src="https://www.buzzsprout.com/883858?iframe=true&limit=1" title="Continuous Delivery Podcast" className='w-full' style={{height: "500px"}}></iframe></div> : null }
        { content.key === DEFINITELY_MAYBE_AGILE_PODCAST ? <div className="overflow-hidden" style={{height: "200px"}}><iframe src="https://www.buzzsprout.com/1643821?iframe=true&limit=1" title="Definitely, Maybe Agile" className='w-full' style={{height: "500px"}}></iframe></div> : null }
        { content.key === GOOD_INTENTIONS_PODCAST ? <div className="overflow-hidden" style={{height: "200px"}}><iframe src="https://www.buzzsprout.com/2322157?iframe=true&limit=1" title="Good Intentions, Bad Outcomes" className='w-full' style={{height: "500px"}}></iframe></div> : null }
      </div>
    </Section>
  ))

  return <Layout title={page.name} darkFooter>
    <Helmet>
      <meta name="description" content={page.metaDescription || defaultMetadata.metaDescription} />
    </Helmet>
    <AssertPage id={page.contentful_id} url={location.pathname} />
    <HeaderPage className={sectionEndLine} stretch hideControl padding='p-0'>
      <Carousel className='w-full h-full pb-8' focus={new URLSearchParams(location.search).get("focus")}>
        {componentsForCarousel || []}
      </Carousel>
    </HeaderPage>
    <FutureEventsSection events={events && events.future} />
    <PageWidth className='bg-primary text-page'>
      <Section>
        <div className='mt-8 text-center'>
          <PageHeading>Past events</PageHeading>
          <Paragraph>We presented at these events</Paragraph>
        </div>
        <PastEvents>{events && events.past}</PastEvents>
      </Section>
    </PageWidth>
  </Layout>
}

function withDate() {
  return mapper

  function mapper(event) {
    return { ...event, date: new Date(event.date) }
  }
}

function FutureEventsSection({ events }) {
  if (!events || events.length === 0) return null
  return <PageWidth className='py-12'>
      <Section cols='auto'>
        <div className='pt-8'>
          <PageHeading>Check out our schedule</PageHeading>
          <Paragraph>You can hear us at any of these upcoming events</Paragraph>
        </div>
        <div className='divide-y-4 divide-primary'>
          {events.map(event => <FutureEvent event={event} />)}
        </div>
      </Section>
    </PageWidth>
}

function FutureEvent({ event }) {
  return <div className='py-4'>
    <div className='flex items-end'>
      <SectionHeading><EventLink event={event} color='text-current'>{dateFormat.format(event.date)}</EventLink></SectionHeading>
      <div className='flex-grow text-right'>{timeFormat.format(event.date)}</div>
    </div>

    <h3 className='text-xl'><EventLink event={event} color='text-current'>{event.title}</EventLink></h3>
    {event.description && <RichText body={event.description.json} className={'mt-4'}/>}
    <div className='flex'>
      <TeamMembers>{event.teamMembers}</TeamMembers>
      <EventLink event={event} showAlways={false} className='flex-grow text-right'>More info</EventLink>
    </div>
  </div>
}

function EventLink({event, showAlways = true, color, className, children}) {
  if (event.url)
    return <Link href={event.url} color={color} className={className}>{children}</Link>
  return showAlways ? children : null
}

function FutureEventCarouselEntry({ event }) {
  const descriptionStyles = {
    [INLINES.HYPERLINK]: (node, children) => <Link href={node.data.uri} color='text-current' className='font-bold'>{children}</Link>
  }

  return <Section cols='auto' className='bg-primary text-page h-full items-center'>
    {event.eventType && <EventLink event={event}>
      <Image image={event.image || event.eventType.image} alt={event.eventType.description} className='mb-4 bg-page' />
    </EventLink>}
    <div className='md:order-first'>
      <PageHeading>
        <EventLink event={event} color='text-current'>{event.title}</EventLink>
      </PageHeading>
      <div className='uppercase'>Date</div>
      <div className='font-bold'><EventLink event={event} color='text-current'>{longDateFormat.format(event.date)}</EventLink></div>
      <div className='uppercase'>Time</div>
      <div className='font-bold'>{longTimeFormat.format(event.date)}</div>
      {event.description && <RichText body={event.description.json} className='mt-4' styles={descriptionStyles} />}
      <div className='flex gap-x-1 mb-4'>
        <TeamMembers>{event.teamMembers}</TeamMembers>
      </div>
    </div>
  </Section>
}

function PastEvents({ className, children }) {
  const paddingStyle = [
    'md:pr-6',
    'md:pl-2 md:pr-4',
    'md:pl-4 md:pr-2',
    'md:pl-6'
  ]
  return <div className={join(className, 'md:grid md:grid-cols-4 gap-y-1 bg-page')}>
    {children && children.map((event, index) => <PastEvent event={event} className={paddingStyle[index % 4]} />)}
    {children && Array(3 - (children.length - 1) % 4).fill(undefined).map(() => <div className='bg-primary'>&nbsp;</div>)}
  </div>
}

function PastEvent({ event, className }) {
  return <div className={join(className, 'bg-primary text-page py-8')}>
    {event.eventType && <Image image={event.image || event.eventType.image} alt={event.eventType.description} className='bg-page mb-4' />}
    <div className='font-bold'>{event.url ? <a href={event.url} color='text-current'>{event.title}</a> : event.title}</div>
    <div className='flex gap-x-1'>
      <div className='flex-grow'>{dateFormat.format(event.date)}</div>
    </div>
  </div>
}

function TeamMembers({children}) {
  return children && children.map(member => <Image image={member.image} alt={member.name} className='w-10 rounded-full' />)
}

const query = graphql`{
  page: contentfulSiteContentList(key: {eq: "events"}) {
    key
    name
    contentful_id
    contents {
      ... on ContentfulSiteContent {
        ...Content
      }
    }
  }
  sortedEvents: allContentfulSiteEvent(sort: {order: DESC, fields: date}) {
    nodes {
      contentful_id
      title
      date
      url
      description {
        raw
      }
      teamMembers {
        name
        image {
          ...ImageContent
        }
      }
      eventType {
        description
        image {
          ...ImageContent
        }
      }
    }
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  }
}`